import { AnchorHTMLAttributes, FC } from 'react';

interface LinkableProps extends AnchorHTMLAttributes<HTMLAnchorElement> {}

const Linkable: FC<LinkableProps> = (props) => {
  const { href, children, ...rest } = props;

  if (href) {
    return (
      <a {...rest} href={href}>
        <span className='link nav-link --no-bottom-animation'>{children}</span>
      </a>
    );
  }

  return <>{children}</>;
};

export default Linkable;
