const Location = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99984 15.5002C4.94725 14.6023 3.97166 13.6181 3.08317 12.5577C1.74984 10.9652 0.166505 8.59351 0.166505 6.3335C0.165321 3.97312 1.58671 1.84466 3.7674 0.941332C5.94809 0.0379984 8.45825 0.537865 10.1265 2.20766C11.2236 3.29984 11.8381 4.78549 11.8332 6.3335C11.8332 8.59351 10.2498 10.9652 8.9165 12.5577C8.028 13.6181 7.05242 14.6023 5.99984 15.5002ZM5.99984 3.8335C5.10667 3.8335 4.28135 4.31 3.83478 5.0835C3.3882 5.857 3.3882 6.81001 3.83478 7.58351C4.28135 8.35701 5.10667 8.83351 5.99984 8.83351C7.38059 8.83351 8.49984 7.71417 8.49984 6.3335C8.49984 4.95278 7.38059 3.8335 5.99984 3.8335Z"
        fill="#E0E0E0"
      />
    </svg>
  );
};

export default Location;
