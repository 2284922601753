export enum ABOUT_TAGS {
  ABOUT = 'hash-about',
  INTRO = 'hash-about-intro',
  VISION = 'hash-about-vision',
  MISSION = 'hash-about-mission',
  LEARNMORE = 'hash-about-learnmore-url',
}

export enum HOME_TAGS {
  HOME = 'hash-homepage',
  HERO = 'hash-homepage-hero',
  TECH = 'hash-homepage-tech',
  TECHNOLOGIES = 'hash-homepage-technologies',
  WORKS = 'hash-homepage-works',
  LEADERSHIP = 'hash-homepage-leadership',
  TECHANDOPS = 'hash-homepage-technops',
  LEARNMORE = 'hash-homepage-learnmore-url',
}

export enum INVERSTORS_TAGS {
  INVERSTOR = 'hash-investors',
  HERO = 'hash-investors-hero',
  BENEFITS = 'hash-investors-benefits',
  SECTORS = 'hash-investors-our-sectors',
  LEARNMORE = 'hash-investors-learnmore-url',
}

export enum JOB_TAGS {
  JOBS = 'hash-jobs',
  POSITION_PREFIX = 'hash-jobs-position-',
  CATEGORY_PREFIX = 'hash-jobs-category-',
  BENEFITS = 'hash-jobs-benefits',
}

export enum NEWS_TAGS {
  NEWS = 'hash-news',
  CATEGORY_PREFIX = 'hash-news-category-',
}

export enum LOCATION_TAGS {
  PREFIX = 'hash-location-',
}

export enum AUTHOR_TAGS {
  PREFIX = 'hash-author-',
}

export enum SHARED_TAGS {
  INSTAGRAM = 'hash-instagram',
  DISCORD = 'hash-discord',
  YOUTUBE = 'hash-youtube',
  FACEBOOK = 'hash-facebook',
  TWITTER = 'hash-twitter',
  TIKTOK = 'hash-tiktok',
}
