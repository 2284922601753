import { useLayoutContext } from '@/context/LayoutContext';
import Footer from '@/modules/footer';
import Navigation from '@/modules/navigation';
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';
import StayUpdated from '../stay-updated';

interface BaseLayoutProps extends PropsWithChildren {}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { center, showStayUpdatedForm } = useLayoutContext();

  return (
    <div className="eec-layout">
      <Navigation />

      <div
        className={classNames('eec-content', 'container', {
          '--center': center,
        })}
      >
        {children}

        {showStayUpdatedForm ? (
          <>
            <div className="mb-5">
              <StayUpdated />
            </div>
          </>
        ) : null}
        <div className="py-4" />
      </div>

      <Footer />
    </div>
  );
};

export default BaseLayout;
