import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { TagsService } from '@/components/services/tags.service';
import { SHARED_TAGS } from '@/types/ghost.d';
import { isValidHttpUrl } from '@/utils/url-utils';

interface IContextSharedDataProps {
  instagram?: string;
  discord?: string;
  facebook?: string;
  twitter?: string;
  youtube?: string;
  tiktok?: string;
}

export const SharedDataCtx = createContext({} as IContextSharedDataProps);

export const SharedDataCtxWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [instagram, setInstagram] = useState<string>();
  const [discord, setDiscord] = useState<string>();
  const [facebook, setFacebook] = useState<string>();
  const [twitter, setTwitter] = useState<string>();
  const [youtube, setYoutube] = useState<string>();
  const [tiktok, setTiktok] = useState<string>();

  const fetchSharedData = async () => {
    const tags = await TagsService.getShared();

    tags.forEach((tag) => {
      if (
        tag.slug === SHARED_TAGS.INSTAGRAM &&
        isValidHttpUrl(tag.description)
      ) {
        setInstagram(tag.description!);
      }
      if (tag.slug === SHARED_TAGS.DISCORD && isValidHttpUrl(tag.description)) {
        setDiscord(tag.description!);
      }
      if (
        tag.slug === SHARED_TAGS.FACEBOOK &&
        isValidHttpUrl(tag.description)
      ) {
        setFacebook(tag.description!);
      }
      if (tag.slug === SHARED_TAGS.TWITTER && isValidHttpUrl(tag.description)) {
        setTwitter(tag.description!);
      }
      if (tag.slug === SHARED_TAGS.YOUTUBE && isValidHttpUrl(tag.description)) {
        setYoutube(tag.description!);
      }
      if (tag.slug === SHARED_TAGS.TIKTOK && isValidHttpUrl(tag.description)) {
        setTiktok(tag.description!);
      }
    });
  };

  useEffect(() => {
    fetchSharedData();
  }, []);

  return (
    <SharedDataCtx.Provider
      value={{ instagram, discord, facebook, twitter, youtube, tiktok }}
    >
      {children}
    </SharedDataCtx.Provider>
  );
};

export const useSharedDataContext = () => useContext(SharedDataCtx);
