// Private Environments
export const GHOST_API_URL = String(process.env.GHOST_API_URL);
export const GHOST_API_KEY = String(process.env.GHOST_API_KEY);
export const RECAPTCHA_SECRET: string = String(process.env.RECAPTCHA_SECRET) ?? '';
export const SENDGRID_API_KEY: string = String(process.env.SENDGRID_API_KEY) ?? '';
export const SENDGIRD_LIST_ID: string = String(process.env.SENDGIRD_LIST_ID) ?? '';

// Public Environments
export const SITE_URL = String(process.env.NEXT_PUBLIC_SITE_URL);
export const GTM_ID: string = String(process.env.NEXT_PUBLIC_GTM_ID) ?? '';
export const RECAPTCHA_SITE_KEY: string = String(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) ?? '';
export const GA_MEASUREMENT_ID: string =
  String(process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID) ?? '';
