import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export interface InternalLinkProps {
  to: string;
  children: string | React.ReactElement;

  noLine?: boolean;

  extraClasses?: string;
  onClick?: () => void;
}

export default function InternalLink(props: InternalLinkProps) {
  const router = useRouter();
  const [isActive, setIsActive] = useState(false);

  const classes = classNames('link nav-link', {
    [props.extraClasses as string]: !!props.extraClasses,
    '--no-bottom-animation': props.noLine,
    active: isActive,
  });

  useEffect(() => {
    const slug = router.query.slug as any;
    const pageSlug =
      (Array.isArray(slug) ? slug.join('/') : slug) || router.pathname;

    if (`/${pageSlug}`.replace('//', '/') === props.to) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [props, router]);

  return (
    <Link href={props.to} className="text-decoration-none">
      <span
        className={classes}
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
      >
        <span className="nav-link--animation">{props.children}</span>
      </span>
    </Link>
  );
}
