const Discrod = () => {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.317 2.36995C18.7873 1.66809 17.147 1.15096 15.4319 0.854758C15.4007 0.84904 15.3694 0.863337 15.3534 0.891883C15.1424 1.26712 14.9087 1.75659 14.7451 2.14134C12.9004 1.86515 11.0651 1.86515 9.2583 2.14134C9.09461 1.74806 8.85246 1.26712 8.64054 0.891883C8.62446 0.864274 8.59324 0.849977 8.56202 0.854758C6.8479 1.14998 5.20755 1.6671 3.6769 2.36995C3.66363 2.37566 3.65229 2.38518 3.64474 2.39756C0.533365 7.04591 -0.31901 11.58 0.0991149 16.0579C0.10099 16.0798 0.113318 16.1008 0.130334 16.1141C2.18313 17.6216 4.17166 18.5368 6.12321 19.1435C6.15443 19.153 6.18752 19.1416 6.2074 19.1159C6.66902 18.4854 7.08054 17.8207 7.43336 17.1216C7.45418 17.0807 7.4343 17.0322 7.39174 17.0159C6.73901 16.7684 6.11749 16.4664 5.5196 16.1236C5.4723 16.096 5.46851 16.0284 5.51205 15.996C5.63787 15.9017 5.76372 15.8036 5.88387 15.7046C5.90562 15.6865 5.9359 15.6827 5.96144 15.6941C9.88919 17.4874 14.1415 17.4874 18.0229 15.6941C18.0484 15.6817 18.0787 15.6855 18.1014 15.7036C18.2216 15.8026 18.3474 15.9017 18.4741 15.9959C18.5176 16.0283 18.5148 16.096 18.4675 16.1236C17.8696 16.4731 17.2481 16.7683 16.5944 17.015C16.5519 17.0311 16.5329 17.0807 16.5538 17.1216C16.9142 17.8197 17.3257 18.4844 17.7788 19.1149C17.7978 19.1415 17.8318 19.153 17.863 19.1434C19.824 18.5368 21.8126 17.6216 23.8653 16.114C23.8833 16.1007 23.8947 16.0807 23.8966 16.0588C24.397 10.8819 23.0584 6.38493 20.3481 2.39845C20.3416 2.38518 20.3302 2.37566 20.317 2.36995ZM8.02001 13.3313C6.83749 13.3313 5.8631 12.2457 5.8631 10.9124C5.8631 9.57909 6.81855 8.49346 8.02001 8.49346C9.23083 8.49346 10.1958 9.58865 10.1769 10.9124C10.1769 12.2457 9.22136 13.3313 8.02001 13.3313ZM15.9947 13.3313C14.8122 13.3313 13.8379 12.2457 13.8379 10.9124C13.8379 9.57909 14.7933 8.49346 15.9947 8.49346C17.2056 8.49346 18.1705 9.58865 18.1516 10.9124C18.1516 12.2457 17.2056 13.3313 15.9947 13.3313Z"
        fill="#F2F2F2"
      />
    </svg>
  );
};

export default Discrod;
