export class StayUpdateService {
  public static async submitStayUpdatedForm({
    email,
    token,
  }: {
    email: string;
    token: string;
  }) {
    return await fetch('/api/stay-updated', {
      method: 'POST',
      body: JSON.stringify({
        email,
        token,
      }),
    });
  }
}
