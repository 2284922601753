import { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';

import InternalLink from '@/components/InternalLink';
import { IMAGE_PLACEHOLDER } from '@/shared/common';

export interface NavigationProps {}

const Navigation: FC<NavigationProps> = () => {
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const [needCloseMobileMenu, setNeedCloseMobileMenu] = useState(false);
  const [top, setTop] = useState<number | string>(0);
  const { width = 0 } = useWindowSize();

  const closeNav = () => {
    setIsMobileMenuActive(false);
  };

  useEffect(() => {
    if (width < 768) {
      if (needCloseMobileMenu) {
        setNeedCloseMobileMenu(false);
        setIsMobileMenuActive(false);
      }
    } else {
      setNeedCloseMobileMenu(true);
      if (isMobileMenuActive) {
        setIsMobileMenuActive(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  useEffect(() => {
    const observer = new MutationObserver(styleChangedCallback);
    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ['style'],
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const styleChangedCallback = () => {
    var newTop = document.body.style.top;
    setTop(newTop);
  };

  return (
    <header className="header" style={{ top }}>
      <div className="header--background"></div>
      <div className="container">
        <div className="header-content">
          <Link
            href={'/'}
            aria-label="logo"
            className={classNames('header-content--logo', {
              'is-active': isMobileMenuActive,
            })}
          >
            <Image
              src={'/assets/images/logo.png'}
              alt=""
              width={160}
              height={34}
              style={{ objectFit: 'cover' }}
              loading="lazy"
              blurDataURL={IMAGE_PLACEHOLDER}
            />
          </Link>
          <button
            className={
              'hamburger hamburger--collapse' +
              (isMobileMenuActive ? ' is-active' : '')
            }
            type="button"
            aria-label="menu"
            aria-controls="navigation"
            onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <div className="header-content--nav">
            <InternalLink to="/" onClick={closeNav} extraClasses="me-md-3">
              Home
            </InternalLink>
            <InternalLink
              to="/about-us"
              onClick={closeNav}
              extraClasses="me-md-3"
            >
              About
            </InternalLink>
            <InternalLink
              to="/investors"
              onClick={closeNav}
              extraClasses="me-md-3"
            >
              Investors
            </InternalLink>

            {/* <div className="d-flex me-md-3">
              <InternalLink to="/jobs" onClick={closeNav}>
                Jobs
              </InternalLink>
              <sup>
                <span className="badge bg-danger ms-2" style={{ fontSize: 8 }}>
                  we’re hiring
                </span>
              </sup>
            </div>

            <InternalLink to="/news" onClick={closeNav}>
              News
            </InternalLink> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
