import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface IContextLayoutProps {
  center?: boolean;
  showStayUpdatedForm?: boolean;
  setCenter?: Dispatch<SetStateAction<boolean | undefined>>;
  setShowStayUpdatedForm?: Dispatch<SetStateAction<boolean | undefined>>;
}

export const LayoutCtx = createContext<IContextLayoutProps>({});

export const LayoutCtxWrapper: FC<PropsWithChildren> = (props) => {
  const { children } = props;

  const [center, setCenter] = useState<boolean>();
  const [showStayUpdatedForm, setShowStayUpdatedForm] = useState<boolean>();

  return (
    <LayoutCtx.Provider
      value={{ center, setCenter, showStayUpdatedForm, setShowStayUpdatedForm }}
    >
      {children}
    </LayoutCtx.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutCtx);
