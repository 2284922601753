const Search = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4514 11.0712L6.64137 7.26058C4.94644 8.46558 2.61099 8.17085 1.26861 6.58252C-0.073766 4.99425 0.0248005 2.64232 1.49536 1.17193C2.96553 -0.299104 5.31771 -0.398097 6.90631 0.94421C8.49484 2.28651 8.78977 4.62223 7.58471 6.31725L11.3947 10.1279L10.452 11.0706L10.4514 11.0712ZM4.32336 1.33326C3.05916 1.33297 1.96849 2.22038 1.71167 3.45822C1.45487 4.69605 2.1025 5.94412 3.26248 6.44678C4.42245 6.94938 5.77597 6.56852 6.50351 5.53465C7.23111 4.50078 7.13271 3.09814 6.26804 2.17593L6.67137 2.57593L6.21671 2.1226L6.20871 2.1146C5.70991 1.61272 5.03097 1.33135 4.32336 1.33326Z"
        fill="white"
      />
    </svg>
  );
};

export default Search;
