import { RECAPTCHA_SECRET, RECAPTCHA_SITE_KEY } from '@/shared/env';

export class ReCaptchaService {
  public static reCaptcheExcute() {
    return new Promise<string>((resolve, reject) => {
      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
            action: 'submit',
          });

          resolve(token);
        } catch (ex) {
          reject(ex);
        }
      });
    });
  }

  public static async reCaptcheVerify({
    token,
    remoteip,
  }: {
    token: string;
    remoteip?: string;
  }) {
    const response = await fetch(
      'https://www.google.com/recaptcha/api/siteverify',
      {
        method: 'POST',
        body: JSON.stringify({
          secret: RECAPTCHA_SECRET,
          response: token,
          remoteip,
        }),
      }
    );

    return response.json();
  }
}
