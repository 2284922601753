import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

interface TextHighLight extends PropsWithChildren {
  nowrap?: boolean;
}

const TextHighLight: FC<TextHighLight> = ({ nowrap, children }) => {
  return (
    <span className={classNames('highlight', { 'text-nowrap': nowrap })}>
      {children}
    </span>
  );
};

export default TextHighLight;
