const Left = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.2869 12.0002L14.2979 5.99023L15.7119 7.40423L11.1119 12.0042L15.7119 16.5972L14.2979 18.0112L8.2869 12.0002Z"
        fill="white"
      />
    </svg>
  );
};

export default Left;
