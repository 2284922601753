import ArrowLeft from './icons/ArrowLeft';
import Discrod from './icons/discord';
import Facebook from './icons/facebook';
import Instagram from './icons/instagram';
import Left from './icons/Left';
import Location from './icons/Location';
import MailOutline from './icons/mail-outline';
import Right from './icons/Right';
import Search from './icons/Search';
import Share from './icons/Share';
import Twitter from './icons/twitter';
import Youtube from './icons/youtube';

export enum IconType {
  Facebook = 'Facebook',
  Twitter = 'Twitter',
  Youtube = 'Youtube',
  Discord = 'Discord',
  Instagram = 'Instagram',
  MailOutline = 'MailOutline',
  ArrowLeft = 'ArrowLeft',
  Location = 'Location',
  Share = 'Share',
  Search = 'Search',
  Left = 'Left',
  Right = 'Right',
}

interface IconProps {
  type: IconType | undefined;
}

const Icon = ({ type }: IconProps) => {
  switch (type) {
    case IconType.Facebook:
      return <Facebook />;
    case IconType.Twitter:
      return <Twitter />;
    case IconType.Youtube:
      return <Youtube />;
    case IconType.Discord:
      return <Discrod />;
    case IconType.Instagram:
      return <Instagram />;
    case IconType.MailOutline:
      return <MailOutline />;
    case IconType.ArrowLeft:
      return <ArrowLeft />;
    case IconType.Location:
      return <Location />;
    case IconType.Share:
      return <Share />;
    case IconType.Search:
      return <Search />;
    case IconType.Left:
      return <Left />;
    case IconType.Right:
      return <Right />;
    default:
      return null;
  }
};

export default Icon;
