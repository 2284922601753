import '@/styles/globals.scss';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import BaseLayout from '@/components/layout/BaseLayout';
import { LayoutCtxWrapper } from '@/context/LayoutContext';
import { SharedDataCtxWrapper } from '@/context/SharedDataContext';
import { GTM_ID, RECAPTCHA_SITE_KEY, GA_MEASUREMENT_ID } from '@/shared/env';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta charSet='UTF-8' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=2, minimum-scale=1, user-scalable=yes'
        />
        <title>Eternal Engine Corp</title>
        <meta
          name='description'
          content='Our applications benefit communities and bring value to our investors. We also integrate and bridge into multiple blockchains and legacy systems. We build secure, stable, performance driven and highly scalable products.'
        />

        <meta
          property='og:image'
          content='/assets/images/cover_primary.svg'
        />
        <meta
          property='og:type'
          content='website'
        />
        <meta
          property='og:title'
          content='Eternal Engine Corp'
        />
        <meta
          property='og:description'
          content='Our applications benefit communities and bring value to our investors. We also integrate and bridge into multiple blockchains and legacy systems. We build secure, stable, performance driven and highly scalable products.'
        />

        <meta
          property='twitter:image'
          content='/assets/images/cover_primary.svg'
        />
        <meta
          property='twitter:card'
          content='summary_large_image'
        />
        <meta
          property='twitter:title'
          content='Eternal Engine Corp'
        />
        <meta
          property='twitter:description'
          content='Our applications benefit communities and bring value to our investors. We also integrate and bridge into multiple blockchains and legacy systems. We build secure, stable, performance driven and highly scalable products.'
        />
      </Head>
      <SharedDataCtxWrapper>
        <LayoutCtxWrapper>
          <BaseLayout>
            <Component {...pageProps} />
          </BaseLayout>
        </LayoutCtxWrapper>
      </SharedDataCtxWrapper>
      <Script
        strategy='afterInteractive'
        id='ga-script'
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${GTM_ID}');`,
        }}
      />
      <Script
        strategy='afterInteractive'
        id='google-translate-script'
        dangerouslySetInnerHTML={{
          __html: `
                function googleTranslateElementInit() {
                  new google.translate.TranslateElement(
                    {
                      pageLanguage: 'en',
                      layout: google.translate.TranslateElement.InlineLayout.VERTICAL,
                    },
                    'google_translate_element'
                  )
                }
            `,
        }}
      />
      <Script
        strategy='afterInteractive'
        src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      />
      <Script
        strategy='afterInteractive'
        src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
      />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy='afterInteractive'
      />
      <Script
        id='google-analytics'
        strategy='afterInteractive'
      >
        {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GA_MEASUREMENT_ID}');
  `}
      </Script>
    </>
  );
}
