const Instagram = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_319_1403)">
        <path
          d="M16.8503 0H7.14973C3.20735 0 0 3.20735 0 7.14973V16.8503C0 20.7926 3.20735 24 7.14973 24H16.8503C20.7926 24 24 20.7926 24 16.8503V7.14973C24 3.20735 20.7926 0 16.8503 0ZM21.5856 16.8503C21.5856 19.4655 19.4655 21.5856 16.8503 21.5856H7.14973C4.5345 21.5856 2.4144 19.4655 2.4144 16.8503V7.14973C2.4144 4.53446 4.5345 2.4144 7.14973 2.4144H16.8503C19.4655 2.4144 21.5856 4.53446 21.5856 7.14973V16.8503Z"
          fill="#F2F2F2"
        />
        <path
          d="M12 5.79297C8.57729 5.79297 5.79272 8.57754 5.79272 12.0002C5.79272 15.4228 8.57729 18.2074 12 18.2074C15.4227 18.2074 18.2072 15.4229 18.2072 12.0002C18.2072 8.57749 15.4227 5.79297 12 5.79297ZM12 15.7931C9.90523 15.7931 8.20712 14.095 8.20712 12.0002C8.20712 9.90547 9.90527 8.20737 12 8.20737C14.0947 8.20737 15.7928 9.90547 15.7928 12.0002C15.7928 14.0949 14.0947 15.7931 12 15.7931Z"
          fill="#F2F2F2"
        />
        <path
          d="M18.2193 7.32682C19.0407 7.32682 19.7066 6.6609 19.7066 5.83944C19.7066 5.01798 19.0407 4.35205 18.2193 4.35205C17.3978 4.35205 16.7319 5.01798 16.7319 5.83944C16.7319 6.6609 17.3978 7.32682 18.2193 7.32682Z"
          fill="#F2F2F2"
        />
      </g>
      <defs>
        <clipPath id="clip0_319_1403">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;
