import classNames from 'classnames';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import { useSharedDataContext } from '@/context/SharedDataContext';
import { isValidEmailAddress } from '@/utils/email-utils';
import Button from '../generics/button';
import Icon, { IconType } from '../generics/icon';
import TextHighLight from '../generics/text-highlight';
import TextInput from '../generics/text-input';
import { ReCaptchaService } from '../services/recaptcha.service';
import { StayUpdateService } from '../services/stay-update.service';
import Linkable from '../generics/linkable';

const StayUpdated = () => {
  const sharedData = useSharedDataContext();
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    console.log(inputRef.current?.value);
  }, [inputRef]);

  const _handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    setLoading(true);
    setError('');
    e.preventDefault();

    if (
      !inputRef.current?.value ||
      !isValidEmailAddress(inputRef.current?.value)
    ) {
      setError('Please provide valid email!');
      setLoading(false);
      return;
    }

    try {
      const token = await ReCaptchaService.reCaptcheExcute();
      await StayUpdateService.submitStayUpdatedForm({
        email: inputRef.current?.value,
        token,
      });
      setSuccess(true);
    } catch (ex) {
      console.error(ex);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="eec-stay-updated">
      <div className="card">
        <div className="card-body mt-5 mb-5">
          <h2 className="text-center mb-4">
            Subscribe to <TextHighLight nowrap>stay updated</TextHighLight>
          </h2>
          <div className="d-flex flex-row justify-content-center align-items-center gap-3 mb-4">
            <TextInput
              ref={inputRef}
              className="w-100"
              style={{ maxWidth: 400 }}
              placeholder="Your email address"
              type={'email'}
              required
              onChange={() => setSuccess(false)}
              onBlur={(e) => {
                console.log(e.target.value);
                if (!isValidEmailAddress(e.target.value)) {
                  setError('Please provide valid email!');
                } else {
                  setError('');
                }
              }}
            />
            <Button isLoading={loading} onClick={_handleSubmit}>
              Subscribe
            </Button>
          </div>
          <div
            className={classNames(
              'text-center fw-bold text-primary eec-stay-updated--thanks',
              { active: success && !error }
            )}
          >
            Thanks and welcome to eternalenginecorp.com
          </div>
          <div
            className={classNames(
              'text-center fw-bold eec-stay-updated--thanks',
              { error: !!error }
            )}
          >
            {error}
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3 gap-sm-5">
            <Linkable
              className="text-reset text-decoration-none"
              href={sharedData.discord}
              target="_blank"
            >
              <Icon type={IconType.Discord} />
              <span className="ms-3">Join community</span>
            </Linkable>

            <Linkable
              className="text-reset text-decoration-none"
              href={sharedData.instagram}
              target="_blank"
            >
              <Icon type={IconType.Instagram} />
              <span className="ms-3">Follow us</span>
            </Linkable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayUpdated;
