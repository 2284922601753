import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';

import Icon, { IconType } from '@/components/generics/icon';
import InternalLink from '@/components/InternalLink';
import { useSharedDataContext } from '@/context/SharedDataContext';
import Linkable from '@/components/generics/linkable';

const Footer = () => {
  const sharedData = useSharedDataContext();

  const copyright = `Copyright ${dayjs().year()} Eternal engine corporation`;
  const social = (
    <div className="site-footer--social">
      <Linkable href={sharedData.facebook} target="_blank">
        <Icon type={IconType.Facebook} />
      </Linkable>
      <Linkable href={sharedData.twitter} target="_blank">
        <Icon type={IconType.Twitter} />
      </Linkable>
      <Linkable href={sharedData.youtube} target="_blank">
        <Icon type={IconType.Youtube} />
      </Linkable>
    </div>
  );

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row site-footer--grid">
          <div className="col-12 col-md-3">
            <Link href={'/'} aria-label="Logo" className={'logo'}>
              <Image
                src={'/assets/images/logo.png'}
                alt=""
                width={160}
                height={34}
                style={{ objectFit: 'cover' }}
                loading="lazy"
              />
            </Link>
            <div className="copyright d-none d-md-flex">{copyright}</div>
            <div className="d-flex flex-row flex-md-column">
              <div className="d-none d-md-flex">{social}</div>
              <div id="google_translate_element" className="translation" />
            </div>
          </div>
          <div className="col-4 col-md-3">
            <div className="site-footer--nav-title">Learn</div>
            <div className="site-footer--nav-item">
              <InternalLink noLine to="/about-us">
                About us
              </InternalLink>
            </div>
            <div className="site-footer--nav-item">Team</div>
            <div className="site-footer--nav-item">Contact us</div>
          </div>
          <div className="col-4 col-md-3">
            <div className="site-footer--nav-title">Follow Us</div>
            <div className="site-footer--nav-item">Wizapp blog</div>
            <div className="site-footer--nav-item">
              <Linkable
                href={sharedData.twitter}
                className="text-decoration-none"
                target="_blank"
              >
                Twitter
              </Linkable>
            </div>
            <div className="site-footer--nav-item">
              <Linkable
                href={sharedData.instagram}
                className="text-decoration-none"
                target="_blank"
              >
                Instagram
              </Linkable>
            </div>
            <div className="site-footer--nav-item">
              <Linkable
                href={sharedData.tiktok}
                className="text-decoration-none"
                target="_blank"
              >
                Tiktok
              </Linkable>
            </div>
          </div>
          <div className="col-4 col-md-3">
            <div className="site-footer--nav-title">Invest</div>
            <div className="site-footer--nav-item">
              <InternalLink noLine to="/investors">
                Investors
              </InternalLink>
            </div>
          </div>
        </div>
        <div className="d-md-none">{social}</div>
        <div className="copyright d-md-none">{copyright}</div>
      </div>
    </footer>
  );
};

export default Footer;
