const Share = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66692 8.99983C3.25002 8.9981 3.81107 8.7767 4.23825 8.37983L8.41159 10.7645C8.12252 11.894 8.70659 13.0644 9.78292 13.5126C10.8592 13.9608 12.1014 13.5508 12.6994 12.55C13.2975 11.5492 13.0701 10.261 12.1654 9.5255C11.2608 8.78997 9.95332 8.83017 9.09559 9.61984L4.92225 7.23517C4.966 7.0679 4.99017 6.89603 4.99426 6.72317L9.09492 4.37983C9.90886 5.11979 11.1299 5.1884 12.0216 4.54429C12.9133 3.90017 13.2319 2.71946 12.7852 1.71423C12.3385 0.708994 11.2487 0.154108 10.173 0.384215C9.09732 0.614321 8.32992 1.5665 8.33359 2.6665C8.33599 2.85855 8.36219 3.04957 8.41159 3.23517L4.62226 5.39984C4.01525 4.46078 2.8274 4.08434 1.79032 4.50237C0.75325 4.92041 0.158517 6.01537 0.37243 7.1129C0.586343 8.21037 1.54876 9.0019 2.66692 8.99983Z"
        fill="white"
      />
    </svg>
  );
};

export default Share;
