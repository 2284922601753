const Youtube = () => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9149 0H4.69598C2.10246 0 0 2.10246 0 4.69598V11.304C0 13.8975 2.10246 16 4.69598 16H17.9149C20.5084 16 22.6108 13.8975 22.6108 11.304V4.69598C22.6108 2.10246 20.5084 0 17.9149 0ZM14.739 8.3215L8.5561 11.2704C8.39135 11.3489 8.20104 11.2288 8.20104 11.0463V4.96427C8.20104 4.77916 8.39635 4.6592 8.56144 4.74287L14.7443 7.87606C14.9282 7.9692 14.925 8.23283 14.739 8.3215Z"
        fill="white"
      />
    </svg>
  );
};

export default Youtube;
