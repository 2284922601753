const Twitter = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1262_530)">
        <path
          d="M15.7455 2.89396C15.39 3.05159 15.021 3.17596 14.6423 3.26599C15.0596 2.8695 15.3733 2.37357 15.5503 1.82002C15.6008 1.66199 15.4292 1.52534 15.2865 1.60995C14.7597 1.92242 14.1922 2.15683 13.5999 2.30674C13.5655 2.31544 13.5301 2.31985 13.4945 2.31985C13.3866 2.31985 13.2817 2.27979 13.1992 2.20707C12.5689 1.65162 11.7584 1.3457 10.9168 1.3457C10.5526 1.3457 10.185 1.40246 9.82398 1.51442C8.70549 1.86133 7.84248 2.78395 7.57172 3.92225C7.47014 4.34921 7.44264 4.77656 7.48992 5.19241C7.49534 5.24019 7.47275 5.27354 7.45883 5.28919C7.43437 5.31661 7.39969 5.33233 7.36368 5.33233C7.35966 5.33233 7.35553 5.33214 7.35143 5.33175C4.90311 5.10428 2.69532 3.92475 1.13474 2.01047C1.05516 1.91283 0.902552 1.9248 0.839123 2.0336C0.533521 2.55789 0.372021 3.15754 0.372021 3.76773C0.372021 4.70287 0.747723 5.58414 1.40032 6.22659C1.12588 6.16152 0.860266 6.05904 0.61353 5.922C0.49416 5.85569 0.347289 5.94092 0.345612 6.07746C0.328526 7.46667 1.14188 8.7028 2.3603 9.25943C2.33577 9.26002 2.31123 9.26029 2.28665 9.26029C2.09352 9.26029 1.89796 9.24164 1.70549 9.20486C1.57106 9.17919 1.45813 9.30671 1.49987 9.43704C1.89535 10.6717 2.95844 11.5818 4.23062 11.7885C3.17479 12.4972 1.94524 12.8711 0.66553 12.8711L0.266578 12.8709C0.143659 12.8709 0.0396982 12.951 0.00837342 13.0701C-0.0224832 13.1873 0.0343538 13.311 0.139173 13.372C1.5812 14.2112 3.22604 14.6547 4.89659 14.6547C6.35887 14.6547 7.7267 14.3645 8.96214 13.7923C10.0947 13.2676 11.0955 12.5177 11.9368 11.5632C12.7205 10.6741 13.3334 9.64773 13.7583 8.51263C14.1633 7.43062 14.3774 6.27609 14.3774 5.17384V5.12133C14.3773 4.94434 14.4574 4.77781 14.597 4.66445C15.127 4.23417 15.5885 3.72767 15.9686 3.15903C16.069 3.00888 15.9107 2.8207 15.7455 2.89396Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1262_530">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;
