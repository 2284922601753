import { Tags } from '@tryghost/content-api';

export class TagsService {
  public static async getShared(): Promise<Tags> {
    const res = await fetch(`/api/tags/shared`, {
      method: 'GET',
    });

    return (await res.json()).data;
  }
}
