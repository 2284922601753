import cx from 'classnames';
import { forwardRef } from 'react';

import styles from './text-input.module.scss';

export type HTMLInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'prefix' | 'size'
>;

export interface TextInputProps extends HTMLInputProps {
  placeholder?: string;
  autoCompleteOptions?: string[];
  isDisabled?: boolean;
  className?: string;

  size?: 'lg' | 'sm';
  prefix?: React.ReactNode | string;
  suffix?: string | React.ReactNode;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (props: TextInputProps, ref: any) => {
    const { className, style, prefix, suffix, size, ...rest } = props;

    const _getAddons = (obj: string | React.ReactNode) => {
      if (!obj) return null;
      if (typeof obj === 'string' || typeof obj === 'number') {
        return <span className="input-group-text">{obj}</span>;
      }
      return obj;
    };

    return (
      <div className={cx(styles.container, className)} style={style}>
        {_getAddons(prefix)}
        <input ref={ref} {...rest} className="w-100" />
        {_getAddons(suffix)}
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
